import { NumericFormat } from 'react-number-format';
import './style.css';
import { formatNumber } from 'components/formatos';

function FLOATINPUT({ index = -1,
  campo,
  setNumber,
  nativo = false,
  css = 'NUMEROD2',
  decimalScale = 2,
  disabled,
  number,
  allowNegative = false,
  isAllowed,
  size,
  id
}) {

  function handleNumber(input) {
    
    const iindex = index ?? -1;
     const v = parseFloat(input.toString().replace(',', '.'));
    //const v = input.floatValue;
    if (nativo) {
      setNumber({ target: { name: campo, value: input.floatValue } });
    } else {
      if (iindex >= 0) {
        const campoValue = campo ?? '-1';
        if (campoValue === '-1') {
          setNumber(v, index);
        } else {
          setNumber(v, index, campoValue);
        }
      } else {
        setNumber(v);
      }
    }
  }


  let dS;
  switch (css) {
    case 'NUMEROD0':
      dS = 0;
      break;
    case 'NUMEROD2':
      dS = 2;
      break;
    case 'NUMEROD4':
      dS = 4;
      break;
    default:
      dS = decimalScale ?? 2;
  }
  const isDisabled = disabled ?? false;

  return isDisabled ? <NumericFormat className="form-control  input-right"
    // defaultValue={number.toLocaleString("pt-br", {minimumFractionDigits: dS})}
    defaultValue={number}
    value={number}
    valueIsNumericString={true}
    decimalSeparator=","
    thousandSeparator="."
    decimalScale={dS}
    fixedDecimalScale={dS > 0}
    placeholder="Valor"
    allowNegative={allowNegative ?? false}
    onValueChange={(values) => { handleNumber(values.value) }}
    renderText={(value) => formatNumber(value, dS)}
    isAllowed={isAllowed}
    size={size}
    id={id}
    disabled

  />
    :
    <NumericFormat className="form-control  input-right"
      // defaultValue={number.toLocaleString("pt-br", {minimumFractionDigits: dS})}
      defaultValue={number}
      value={number}
      valueIsNumericString={true}
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={dS}
      fixedDecimalScale={dS > 0}
      placeholder="Valor"
      allowNegative={allowNegative ?? false}
      onValueChange={(values) => { nativo ? handleNumber(values) : handleNumber(values.value) }}
      renderText={(value) => formatNumber(value, dS)}
      isAllowed={isAllowed}
      size={size}
      id={id}

    />
}
export default FLOATINPUT;