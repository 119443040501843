import { useEffect, useState } from "react";
import EDITMODAL from 'components/editmodal';
import api from "services/api";
import { getHoje } from "components/formatos";
import { getadmin, getnome } from "functions/gettabconfig";
import { cepMask } from "functions/formatavalor";
import { foneMask } from "functions/foneMask";
import RADIO from "components/radio";
import CAMERACOMPONENT from "components/cameraimagem";
import Tabs from "components/tabs/tabs";
import './style.css';
import config from 'config';
import SELECT from "components/select";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import EDITVENDEDORES from "./vendedores";
import FLOATINPUT from "components/inputnumber";

function AdminEstabelecimentoModal(props) {

  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [admin] = useState(getadmin());

  const [idestabelecimento, setIDEstabelecimento] = useState(0);
  const [nome, setNome] = useState('');
  const [urlfoto, setUrlfoto] = useState('');
  const [urllogo, setUrllogo] = useState('');
  const [avaliacao, setAvaliacao] = useState(0);
  const [idcategoria, setIDCategoria] = useState(0);
  const [idcupom, setIDCupom] = useState(0);
  const [endereco, setEndereco] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [cep, setCep] = useState('');
  const [uf, setUf] = useState('');
  const [codcidade, setCodCidade] = useState(0);
  const [indativo, setIndAtivo] = useState('N');
  const [qtdavaliacao, setQtdAvaliacao] = useState(0);
  const [vlminpedido, setVlMinPedido] = useState(0.0);
  const [vltaxaentrega, setVlTaxaEntrega] = useState(0.0);
  const [teleforne, setTelefone] = useState('');
  const [whatsup, setWhatsup] = useState('');
  const [iescampanha, setIESCampanha] = useState('N');
  const [idcampanha, setIDCampanha] = useState(0);
  const [edcnpj, setEDCNPJ] = useState('');
  const [site, setSite] = useState('');  
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);  

  const [lcampanha, setlCampanha] = useState([]);

  const [b64foto, setB64Foto] = useState('');
  const [b64logo, setB64Logo] = useState('');

  const [cidades, setCidades] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [avendedores, setAVendedores] = useState([]);

  function salvaCampanha(e) {
    setIDCampanha(e.target.value);
  }

  function salvaEstabelecimento(e) {
    setMensagem('');
    setLoading(true);
    const nomefoto = `/imgest/est${idestabelecimento}.png`
    const nomelogo = `/imglog/log${idestabelecimento}.png`
    const ldados = {
      "nome": nome,
      "url_foto": urlfoto,
      "url_logo": urllogo,
      "avaliacao": avaliacao,
      "id_categoria": idcategoria,
      "id_cupom": idcupom,
      "vl_min_pedido": vlminpedido,
      "vl_taxa_entrega": vltaxaentrega,
      "endereco": endereco,
      "complemento": complemento,
      "bairro": bairro,
      "cidade": cidade,
      "uf": uf,
      "cep": cep,
      "cod_cidade": codcidade,
      "ind_ativo": indativo,
      "qtd_avaliacao": qtdavaliacao,
      "telefone": teleforne,
      "whatsup": whatsup,
      "ies_campanha": iescampanha,
      "id_campanha_def": idcampanha,
      "cnpj": edcnpj,
      "site": site,
      "latitude": latitude,
      "longitude": longitude,
      "vendedores": avendedores
    }
    if (idestabelecimento > 0) {
      api.patch(`${config.ADM_ESTABELECIMENTOS}/${idestabelecimento}`, ldados)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.erro)
          }
        })
    } else {
      api.post(config.ADM_ESTABELECIMENTOS, ldados)
        .then(response => {
          if (response.status === 201) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.erro)
          }
        })
    }
  }

  function Deleta() {
    setMensagem('');
    setLoading(true);
    api.delete(`/v1/adm/estabelecimentos/${idestabelecimento}`)
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          props.onRequestClose();
        } else {
          setLoading(false);
          setMensagem(response.response.data.erro)
        }
      })

  }

  function SalvarCidade(e) {
    const [cid, est] = e.target[e.target.selectedIndex].text.split(' - ');
    setCidade(cid);
    setUf(est);
    setCodCidade(parseInt(e.target.value));
  }

  useEffect(() => {
    setIDEstabelecimento(props.dados.id_estabelecimento ?? 0);
    setNome(props.dados.nome ?? "");
    setUrlfoto(props.dados.url_foto ?? "");
    setUrllogo(props.dados.url_logo ?? "");
    setAvaliacao(props.dados.avaliacao ?? "");
    setIDCategoria(props.dados.id_categoria ?? 0);
    setIDCupom(props.dados.id_cupom ?? 0);
    setEndereco(props.dados.endereco ?? "");
    setComplemento(props.dados.complemento ?? "");
    setBairro(props.dados.bairro ?? "");
    setCidade(props.dados.cidade ?? "");
    setCep(props.dados.cep ?? "");
    setUf(props.dados.uf ?? "");
    setCodCidade(props.dados.cod_cidade);
    setIndAtivo(props.dados.ind_ativo ?? "N");
    setQtdAvaliacao(props.dados.qtd_avaliacao ?? 0);
    setVlMinPedido(props.dados.vl_min_pedido ?? 0);
    setVlTaxaEntrega(props.dados.vl_taxa_entrega ?? 0);
    setTelefone(props.dados.telefone ?? "");
    setWhatsup(props.dados.whatsup ?? "");
    setIESCampanha(props.dados.ies_campanha ?? 'N');
    setIDCampanha(props.dados.id_campanha_def ?? 0);
    setEDCNPJ(props.dados.cnpj ?? '');
    setSite(props.dados.site ?? '');
    setLatitude(props.dados.latitude ?? 0);
    setLongitude(props.dados.longitude ?? 0);
    setAVendedores(props.dados.vendedores ?? []);
  }, [props.dados])

  useEffect(() => {
    if (props.isOpen) {
      api.get(config.ADM_CIDADES)
        .then(response => setCidades(response.data))
        .catch(err => console.log(err));
      api.get(config.ADM_CATEGORIAS)
        .then(response => setCategorias(response.data))
        .catch(err => console.log(err));
      api.get(config.ADM_CAMPANHA)
        .then(response => {
          var lista = [];
          lista.push({ 'id_companha': 0, 'den_campanha': 'Selecione...' })
          lista.push(...response.data);
          setlCampanha(lista)
        })
        .catch(err => console.log(err));
    }
  }, [props.isOpen])

  function validaCNPJ(e) {
    if (e.target.value !== '0') {
      if (!cnpj.isValid(e.target.value)) {
        setMensagem('CNPJ invalido');
        setEDCNPJ('');
        e.target.focus();
      }
    }
  }

  return <EDITMODAL
    programa={'Estabelecimento'}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    loading={loading}
    operacao={props.operacao}
    mensagem={mensagem}
    SalvaParametro={salvaEstabelecimento}
    DeleteParametro={Deleta}
    habilitadelete={admin}
    dcreate={getHoje()}
    ocreate={getnome()}
    dupdate={getHoje()}
    oupdate={getnome()}
  >
    <Tabs>
      <div label='Estabelecimento'>
        <form className="form-estabelecimento">
          <div className="row">
            <div className="col-md-2">
              <label htmlFor="selectCampanha" className="form-label">Estabelecimento</label>
              <input type="text"
                value={idestabelecimento}
                onChange={(e) => setIDEstabelecimento(e.target.value)}
                disabled
                className="form-control" id="inputEstado" />
            </div>
            <div className="col-md-10">
              <label htmlFor="inputNome" className="form-label">Nome</label>
              <input type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className="form-control" id="inputNome" />
            </div>

            <div className="col-md-4">
              <label htmlFor="inputTelefone" className="form-label">Telefone</label>
              <input type="text"
                value={teleforne}
                onChange={(e) => setTelefone(foneMask(e.target.value, true))}
                className="form-control" id="inputTelefone"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputWhatsUP" className="form-label">WhatsApp</label>
              <input type="text"
                value={whatsup}
                onChange={(e) => setWhatsup(foneMask(e.target.value, true))}
                className="form-control" id="inputWhatsUP"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputCEP" className="form-label">CEP</label>
              <input type="text"
                value={cep}
                onChange={(e) => setCep(cepMask(e.target.value))}
                className="form-control" id="inputCEP"
                maxLength={9}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputEndereco" className="form-label">Endereco</label>
              <input type="text"
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
                className="form-control" id="inputEndereco" />
            </div>
            <div className="col-md-2">
              <label htmlFor="inputbairro" className="form-label">Bairro</label>
              <input type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                className="form-control" id="inputbairro" />
            </div>
            <div className="col-md-2">
              <label htmlFor="inputComplemento" className="form-label">Complemento</label>
              <input type="text"
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
                className="form-control" id="inputComplemento" />
            </div>
            <div className="col-md-2">
                <label htmlFor="inputLatitude" className="form-label">Latitude</label>
                <FLOATINPUT
                          id={'inputLatitude'}
                          css={'NUMEROD8'}
                          number={latitude}
                          setNumber={setLatitude}
                          decimalScale={8}
                          allowNegative={true}
                        />
              </div>
              <div className="col-md-2">
                <label htmlFor="inputLongitude" className="form-label">Longitude</label>
                <FLOATINPUT
                          id={'inputLongitude'}
                          css={'NUMEROD8'}
                          number={longitude}
                          setNumber={setLongitude}
                          decimalScale={8}
                          allowNegative={true}
                          
                        />
              </div>

              <div className="col-md-4">
                <label htmlFor="InputCategoria" className="form-lable mb-1">Categoria</label>
                <div className="form-control mb=3">
                  <select className="endereco-cidade"
                    name="categorias" id="categoria"
                    onChange={(e) => setIDCategoria(e.target.value)}
                    value={idcategoria}
                    disabled={!admin}>
                    <option value="00000000">Escolha a Categoria</option>
                    {categorias.map(c => {
                      return <option value={c.id_categoria} key={c.id_categoria}>{c.categoria}</option>
                    })}
                  </select>
                </div>
              </div>


              <div className="col-md-4 mb-3">
                <label htmlFor="InputCidade" className="form-lable mb-1">Cidade</label>
                <div className="form-control mb=3">
                  <select className="endereco-cidade"
                    name="cidades" id="cidade"
                    onChange={SalvarCidade}
                    value={codcidade}
                    disabled={!admin}>
                    <option value="00000000">Escolha a Cidade</option>
                    {cidades.map(c => {
                      return <option value={c.cod_cidade} key={c.cod_cidade}>{c.cidade} - {c.uf}</option>
                    })}
                  </select>
                </div>
              </div>                          
              <div className="col-md-4">
              <label htmlFor="inputSite" className="form-label">Site exemplo https://seusite</label>
              <input type="text"
                value={site}
                onChange={(e) => setSite(e.target.value)}
                className="form-control" id="inputSite" />
              </div>
              {admin && <>
                <div className="col-md-2">
                  <label htmlFor="inputAtivo" className="form-label">OpnetMais</label>
                  <RADIO
                    disabled={!admin}
                    chave={'inputAtivo'}
                    avalores={['S', 'N']}
                    alabel={['Sim', 'Não']}
                    setValor={setIndAtivo}
                    valor={indativo}
                  />
                </div>

                <div className="col-md-2">
                  <label htmlFor="inputIESCampanha" className="form-label">Actus</label>
                  <RADIO
                    disabled={!admin}
                    chave={'inputIESCampanha'}
                    avalores={['S', 'N']}
                    alabel={['Sim', 'Não']}
                    setValor={setIESCampanha}
                    valor={iescampanha}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputFoto" className="form-label">Foto</label>
                  <input type="text" value={urlfoto}
                    className="form-control" id="inputFoto" />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputLogo" className="form-label">Logo</label>
                  <input type="text" value={urllogo}
                    className="form-control" id="inputLogo" />
                </div></>
              }
            
          </div>
        </form>
      </div>
      <div label='Outros'>
        <div className="row">
        <div className="col-md-6">
          <label htmlFor="inputCNPJ" className="form-label">CNPJ</label>
          <div className="input-group">
            <input type="text"
              value={edcnpj}
              onChange={(e) => setEDCNPJ(cnpj.format(e.target.value))}
              onBlur={(e) => validaCNPJ(e)}
              className="form-control" id="inputCNPJ" />

          </div>
        </div>
        <div className="col-md-6">
          <label htmlFor="SELECTCAMPANHA" className="form-label">Campanha</label>
          <SELECT
            key={'0001'}
            name={'SELECTCAMPANHA'}
            SalvaSelect={salvaCampanha}
            value={idcampanha}
            chave={'id_campanha'}
            descricao={'den_campanha'}
            aoptions={lcampanha}
            retornoe={true}
          />
        </div>
        </div>
      </div>
      <div label='Imagem'>
        <div className="row mt-2">
          <div className="col-md-6">
            <CAMERACOMPONENT
              heigth={300}
              width={150}
              foto={urlfoto}
              setFoto={setUrlfoto}
              className={'foto'}
              base64={false}
              setImgB64={setB64Foto}
              nomedoarquivo={`est${idestabelecimento}.png`}
              diretorio={'imgest'}
            />
          </div>
          <div className="col-md-6">
            <CAMERACOMPONENT
              heigth={50}
              width={50}
              foto={urllogo}
              setFoto={setUrllogo}
              className={'logo'}
              base64={false}
              setImgB64={setB64Logo}
              nomedoarquivo={`log${idestabelecimento}.png`}
              diretorio={'imglog'}
            />
          </div>
        </div>
        
      </div>
      <div label="Vendedores">
          <EDITVENDEDORES
            id_estabelecimento={idestabelecimento}
            avendedores={avendedores}
            setAVendedores={setAVendedores}
          />
        </div>
    </Tabs>
  </EDITMODAL>
}

export default AdminEstabelecimentoModal;