import { useEffect, useState } from "react";
import NAVBAR from "components/navbar";
import api from "services/api";
import AdminPromocaoModal from './modal';
import './style.css';
import { formatDate, getHoje } from "components/formatos";
import config from 'config';
import FOOTER from "components/footer";

function AdminPromocoes(){
    const [lpromocoes, setLPromocoes] = useState([]);

    // Modal
    const [operacao, setOperacao] = useState('')
    const [dados, setDados] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    function closeAdminPromocaoModal(){
        setIsOpen(false);
    }

    useEffect(()=>{
        api.get(config.ADM_PROMOCOES)
        .then( response => {
            setLPromocoes(response.data);
        });
    },[isOpen])

    useEffect(()=>{
        api.get(config.ADM_PROMOCOES)
        .then( response => {
            setLPromocoes(response.data);
        });
    },[])

    function novoPromocao(){
        setDados(           
            {
                "id_promocao": 0,
                "id_estabelecimento": 0,
                "data_inicio": getHoje(),
                "data_final": getHoje(),
                "ies_pago": 'N',
                "image": "",
                "linkproduto": ""
            } 
        );
        setIsOpen(true);
    }

    function editarPromocao(linha){
        setOperacao('UPDATE')
        setDados(linha);
        setIsOpen(true);
    }

    return <div>
        <NAVBAR />
        <AdminPromocaoModal isOpen={isOpen}
                  onRequestClose={closeAdminPromocaoModal}                 
                  operacao={operacao}
                  dados={dados}
        /> 
        <div className="mt-page">
            <h1>Promoções por Estabelecimento</h1>
            <div className="table-container">
                <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                    <th scope="col"># <button type="button"
                    onClick={(e)=>novoPromocao()}
                    >Nova</button></th>
                    <th scope="col">ID</th> 
                    <th scope="col" className="d-none d-md-table-cell hidden-sm">Estabelecimento</th>                                
                    <th scope="col">Inicio</th>
                    <th scope="col">Final</th>
                    <th scope="col">operação</th>
                    </tr>
                </thead>        
                <tbody>
                    {
                        lpromocoes.map((e,index) =>{
                            return <tr key={`prom${index}`}>
                                <td scope="row">{e.id_promocao}</td>
                                <td scope="row">{e.id_promocao}</td>
                                <td className="d-none d-md-table-cell hidden-sm">{e.nomeestabelecimento}</td>
                                <td>{formatDate(e.dat_inicio)}</td> 
                                <td>{formatDate(e.dat_final)}</td>                                
                                <td><button className='btn btn-warnning' 
                                    type="button"
                                    onClick={()=>editarPromocao(e)}>Editar</button></td>
                            </tr>           
                        })   
                    }            
                </tbody>
                </table>
            </div>
        </div>
        <FOOTER />
    </div>
}

export default AdminPromocoes;