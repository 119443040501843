import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapPage = () => {
  const [position, setPosition] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [address, setAddress] = useState('');

  const handleGeocode = async () => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=AIzaSyCN6w8zMZgh0a0mfsCgOY8HAzxezciqqts`;

    const response = await fetch(geocodeUrl);
    const data = await response.json();

    if (data.results.length > 0) {
      const location = data.results[0].geometry.location;
      setMarkerPosition([location.lat, location.lng]);
    } else {
      alert('Endereço não encontrado');
    }
  };

  const handleMarkerDragEnd = (event) => {
    const marker = event.target;
    const newPosition = marker.getLatLng();
    setMarkerPosition([newPosition.lat, newPosition.lng]);
  };

  const LocationMarker = () => {
    const map = useMap();

    if (position) {
      map.setView(position, 13);
    }

    return (
      markerPosition && (
        <Marker
          position={markerPosition}
          draggable={true}
          eventHandlers={{
            dragend: handleMarkerDragEnd,
          }}
        />
      )
    );
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          setPosition([latitude, longitude]);
        },
        () => {
          alert('Não foi possível obter a localização');
        }
      );
    }
  }, []);

  return (
    <div>
      <input
        type="text"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder="Digite um endereço"
      />
      <button onClick={handleGeocode}>Buscar</button>

      {position && (
        <MapContainer center={position} zoom={13} style={{ height: '500px', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationMarker />
        </MapContainer>
      )}
    </div>
  );
};

export default MapPage;