import { useEffect, useState } from "react";
import EDITMODAL from 'components/editmodal';
import api from "services/api";
import { getHoje } from "components/formatos";
import { getnome } from "functions/gettabconfig";
import config from 'config';
import CAMERACOMPONENT from "components/cameraimagem";
import './style.css';
import SELECT from "components/select";
import FLOATINPUT from "components/inputnumber";
import RADIO from "components/radio";

function AdminPromocaoModal(props) {
  const { v4: uuidv4 } = require('uuid');
  const [idpromocao, setIdpromocao] = useState(0);
  const [idestabelecimento, setIdestabelecimento] = useState('0');
  const [imagem, setImagem] = useState('');
  const [datinicio, setDatInicio] = useState(getHoje());
  const [datfinal, setDatFinal] = useState(getHoje());
  const [linkproduto, setLinkProduto] = useState('');
  const [valdesconto, setValDesconto] = useState(0.00);
  const [porcdesconto, setPorcDesconto] = useState(0.00);
  const [valminimo, setValMinimo] = useState(0.00);
  const [codproduto, setCodProduto] = useState('');
  const [regra, setRegra] = useState('');
  const [isepago, setIsEPago] = useState('N');

  const [mensagem, setMensagem] = useState('');
  const [loading, setLoading] = useState(false);

  const [lparceiros, setLParceiros] = useState([]);


  function salvaPromocao() {
    const dados =
    {
      "id_promocao": idpromocao,
      "id_estabelecimento": idestabelecimento,
      "dat_inicio": datinicio,
      "dat_final": datfinal,
      "ies_pago": 'N',
      "image": `${config.BASEIMG}/${imagem}`,
      "linkproduto": linkproduto,
      "val_desconto": valdesconto,
      "porc_desconto": porcdesconto,
      "val_minimo": valminimo,
      "cod_produto": codproduto,
      "regra": regra
    }
    setLoading(true);
    if (idpromocao === 0) {
      api.post(`${config.ADM_PROMOCOES}`, dados)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.erro)
          }
        })
    } else {
      api.patch(`${config.ADM_PROMOCOES}/${idpromocao}`, dados)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.erro)
          }
        })
    }

  }

  function deletaPromocao(id) {
    setLoading(true);
    api.delete(`${config.ADM_PROMOCOES}/${idpromocao}`)
      .then(response => {
        if (response.status === 202) {
          setLoading(false);
          props.onRequestClose();
        } else {
          setLoading(false);
          setMensagem(response.response.data.erro)
        }
      })
  }

  useEffect(() => {
    if (props.dados.id_promocao !== undefined) {
      api.get(config.ADM_ESTABELECIMENTOS)
        .then(response => {
          if (response.status === 200) {
            // endpoisn ja filtra estabelecimentos que sou adminstrador
            setLParceiros(response.data);
          }
        })
      setIdpromocao(props.dados.id_promocao ?? 0);
      setIdestabelecimento(props.dados.id_estabelecimento ?? 0);
      setDatFinal(props.dados.dat_final ?? getHoje());
      setDatInicio(props.dados.dat_inicio ?? getHoje());
      setImagem((props.dados.imagem ?? '').replace(config.BASEIMG, ''));
      setValDesconto(props.dados.val_desconto ?? 0.00);
      setPorcDesconto(props.dados.porc_desconto ?? 0.00);
      setValMinimo(props.dados.val_minimo ?? 0.00);
      setCodProduto(props.dados.cod_produto ?? '');
      setRegra(props.dados.regra ?? '');
      setLinkProduto(props.dados.linkproduto ?? '');
      setIsEPago(props.dados.ies_pago ?? 'N');

    }

  }, [props.dados])

  function setNovoEstabelecimento(e) {
    const id = parseInt(e.target.value, 10);
    setIdestabelecimento(id);
  }

  return <EDITMODAL
    programa={'PROMOÇÕES'}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    loading={loading}
    operacao={props.operacao}
    mensagem={mensagem}
    SalvaParametro={salvaPromocao}
    DeleteParametro={deletaPromocao}
    dcreate={getHoje()}
    ocreate={getnome()}
    dupdate={getHoje()}
    oupdate={getnome()}
  >
    <div>
      <div className="row">
        <div className="col-md-2">
          <label htmlFor="forID" className="form-label">ID</label>
          <input type="text"
            value={idpromocao}
            onChange={(e) => setIdpromocao(e.target.value)}
            disabled
            className="form-control" id="forTitulo" />
        </div>
        <div className="col-md-6">
          <label htmlFor="SELECTPARCEIRO" className="form-label">Parceiro</label>
          <SELECT
            key={'SELECTPARCEIRO'}
            name={'SELECTPARCEIRO'}
            SalvaSelect={setNovoEstabelecimento}
            value={idestabelecimento}
            chave={'id_estabelecimento'}
            descricao={'nome'}
            aoptions={lparceiros}
            retornoe={true}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="selectPAGO" className="form-label">Recebido?</label>
          <RADIO
            chave={'selectPAGO'}
            avalores={['S', 'N']}
            alabel={['Sim', 'Não']}
            setValor={setIsEPago}
            valor={isepago}
            disabled={true}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="forProduto" className="form-label">Link Produto</label>
          <input type="text"
            value={linkproduto}
            onChange={(e) => setLinkProduto(e.target.value)}
            className="form-control" id="forProduto" />
        </div>
        <div className="col-md-6">
          <label htmlFor="forCodProduto" className="form-label">Cod.Produto</label>
          <input type="text"
            value={codproduto}
            onChange={(e) => setCodProduto(e.target.value)}
            className="form-control" id="forCodProduto" />
        </div>
        <div className="col-md-4">
          <label htmlFor="forValDesconto" className="form-label">Valor Desconto</label>
          <FLOATINPUT
            css={'NUMEROD4'}
            value={valdesconto}
            setNumber={setValDesconto}
            decimalScale={4}
            className="form-control" id="forValDesconto" />
        </div>
        <div className="col-md-4">
          <label htmlFor="forPorDesconto" className="form-label">% Desconto</label>
          <FLOATINPUT
            css={'NUMEROD4'}
            value={porcdesconto}
            setNumber={setPorcDesconto}
            decimalScale={4}
            className="form-control" id="forPorDesconto" />
        </div>
        <div className="col-md-4">
          <label htmlFor="forValMinimo" className="form-label">Valor Minimo</label>
          <FLOATINPUT
            css={'NUMEROD4'}
            value={valminimo}
            setNumber={setValMinimo}
            decimalScale={4}
            className="form-control" id="forValMinimo" />
        </div>
        <div className="col-md-12">
          <label htmlFor="forRegra" className="form-label">Regra</label>
          <textarea type="text"
            value={regra}
            onChange={(e) => setRegra(e.target.value)}
            max={200}
            className="form-control" id="forRegra" />
        </div>
        <div className="col-md-6">
          <label htmlFor="forInicio" className="form-label">Inicio</label>
          <input type="date"
            value={datinicio}
            onChange={(e) => setDatInicio(e.target.value)}
            className="form-control" id="forInicio" />
        </div>
        <div className="col-md-6">
          <label htmlFor="forFinal" className="form-label">Final</label>
          <input type="date"
            value={datfinal}
            onChange={(e) => setDatFinal(e.target.value)}
            className="form-control" id="forFinal" />
        </div>
        <div className="col-md-10">
          <label htmlFor="forImagem" className="form-label">Link Imagem {imagem}</label>
          <CAMERACOMPONENT
            heigth={200}
            width={200}
            foto={imagem}
            setFoto={setImagem}
            class={'foto-aviso'}
            base64={false}
            nomedoarquivo={`promocao${uuidv4()}.png`}
            diretorio={'imgprom'}
          />
        </div>
      </div>
    </div>
  </EDITMODAL>
}

export default AdminPromocaoModal;